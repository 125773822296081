<i18n src="@/locales/pages/fhg-news.json"></i18n>

<template>
  <div>
    <PortfolioIntro id="intro">
      <template #title>
        {{ $t('portfolio.fhgNews.intro.title') }}
      </template>
      <template #subtitle>
        {{ $t('portfolio.fhgNews.intro.subtitle') }}
      </template>
      <template #description>
        {{ $t('portfolio.fhgNews.intro.description') }}
      </template>

      <template #services>
        {{ $t('portfolio.fhgNews.intro.details.services') }}
      </template>
      <template #tools>
        {{ $t('portfolio.fhgNews.intro.details.tools') }}
      </template>
      <template #year>
        {{ $t('portfolio.fhgNews.intro.details.year') }}
      </template>
      <template #preview>
        <a target="_blank" href="https://fhgnews.de">fhgnews.de</a>
      </template>
    </PortfolioIntro>
    <section id="idea">
      <div class="outer">
        <div class="content">
          <div class="inner">
            <div class="text">
              <p
                class="background-text rellax"
                data-rellax-speed="-1"
                data-rellax-percentage="0.5"
              >
                {{ $t('portfolio.fhgNews.idea.title.short') }}
              </p>
              <h2 class="title">
                {{ $t('portfolio.fhgNews.idea.title.long') }}
              </h2>
              <article
                v-for="(block, i) in $t('portfolio.fhgNews.idea.blocks')"
                :key="i"
              >
                <h3>{{ block.heading }}</h3>
                <p v-if="typeof block.text == 'string'">{{ block.text }}</p>
                <p v-else v-for="(text, i) in block.text" :key="i">
                  {{ text }}
                </p>
              </article>
            </div>
            <picture class="mockup">
              <source
                media="(max-width: 599px)"
                srcset="@/assets/portfolio/fhg-news/mobileMockup.webp"
                type="image/webp"
              />
              <source
                media="(max-width: 599px)"
                srcset="@/assets/portfolio/fhg-news/mobileMockup.png"
                type="image/png"
              />
              <source
                srcset="@/assets/portfolio/fhg-news/introMockup.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/fhg-news/introMockup.png"
                type="image/png"
              />
              <img src="@/assets/portfolio/fhg-news/introMockup.png" alt="" />
            </picture>
          </div>
        </div>
      </div>
    </section>
    <section id="concept">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgNews.concept.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgNews.concept.title.long') }}
        </h2>
        <article class="editors">
          <h3>
            {{ $t('portfolio.fhgNews.concept.editors.heading') }}
          </h3>
          <p>
            {{ $t('portfolio.fhgNews.concept.editors.description') }}
          </p>
          <picture>
            <source
              media="(max-width: 599px)"
              :srcset="
                require(`@/assets/portfolio/fhg-news/mobileFlowChart${languageSuffix}.svg`)
              "
              type="image/svg+xml"
            />
            <source
              :srcset="
                require(`@/assets/portfolio/fhg-news/flowChart${languageSuffix}.svg`)
              "
              type="image/svg+xml"
            />
            <img
              :src="
                require(`@/assets/portfolio/fhg-news/flowChart${languageSuffix}.svg`)
              "
              alt="Flow Chart"
            />
          </picture>
        </article>
        <article class="readership">
          <h3>
            {{ $t('portfolio.fhgNews.concept.readership.heading') }}
          </h3>
          <p
            v-for="(text, i) in $t(
              'portfolio.fhgNews.concept.readership.description'
            )"
            :key="i"
          >
            {{ text }}
          </p>
        </article>
      </div>
    </section>
    <section id="target">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgNews.target.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgNews.target.title.long') }}
        </h2>
        <div class="groups">
          <article
            class="group"
            v-for="(group, i) in $t('portfolio.fhgNews.target.groups')"
            :key="i"
          >
            <h3>
              {{ group.heading }}
            </h3>
            <p class="description" v-html="group.description"></p>
          </article>
        </div>
      </div>
    </section>
    <section id="design">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgNews.design.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgNews.design.title.long') }}
        </h2>
        <article class="ui">
          <h3>User Interface</h3>
          <div class="material">
            <div class="text">
              <i class="icon-material-design icon"></i>
              <p class="heading">
                {{ $t('portfolio.fhgNews.design.ui.material.heading') }}
              </p>
              <ul class="pros">
                <li
                  v-for="(pro, i) in $t(
                    'portfolio.fhgNews.design.ui.material.pros'
                  )"
                  :key="i"
                >
                  {{ pro }}
                </li>
              </ul>
            </div>
            <div class="components">
              <div class="inner">
                <picture>
                  <source
                    srcset="@/assets/portfolio/fhg-news/sc_mobile_home.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="@/assets/portfolio/fhg-news/sc_mobile_home.jpg"
                    type="image/jpg"
                  />
                  <img src="@/assets/portfolio/fhg-news/sc_mobile_home.jpg" />
                </picture>
                <PortfolioMarker
                  v-for="(component, i) in $t(
                    'portfolio.fhgNews.design.ui.material.components.left'
                  )"
                  :class="i"
                  :key="i"
                  color="light"
                  >{{ component }}</PortfolioMarker
                >
                <PortfolioMarker
                  v-for="(component, i) in $t(
                    'portfolio.fhgNews.design.ui.material.components.right'
                  )"
                  align="right"
                  :class="i"
                  :key="i"
                  color="light"
                  >{{ component }}</PortfolioMarker
                >
              </div>
            </div>
          </div>
        </article>
        <article class="colors">
          <div class="text">
            <h3>
              {{ $t('portfolio.fhgNews.design.colors.heading') }}
            </h3>
            <p>
              {{ $t('portfolio.fhgNews.design.colors.description') }}
            </p>
          </div>
          <div class="swatches">
            <PortfolioColorSwatch color="#428ECC" />
            <PortfolioColorSwatch color="#673AB7" />
            <PortfolioColorSwatch color="#F44336" />
            <PortfolioColorSwatch color="#3F51B5" />
            <PortfolioColorSwatch color="#FF9800" />
          </div>
        </article>
        <article class="typography">
          <h3>
            {{ $t('portfolio.fhgNews.design.typography.heading') }}
          </h3>
          <div class="inner">
            <p class="description">
              {{ $t('portfolio.fhgNews.design.typography.description') }}
            </p>
            <div class="fonts">
              <PortfolioFont family="Roboto" />
            </div>
          </div>
        </article>
      </div>
    </section>
    <section id="result">
      <a target="_blank" href="https://fhgnews.de" class="visitSite">
        {{ $t('portfolio.fhgNews.result.visitSite') }}
        <i class="icon-open-in-new"></i>
      </a>
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.fhgNews.result.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.fhgNews.result.title.long') }}
        </h2>
        <div class="gallery">
          <picture class="scMobileHome">
            <source
              srcset="@/assets/portfolio/fhg-news/sc_mobile_home_full.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/fhg-news/sc_mobile_home_full.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/fhg-news/sc_mobile_home_full.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scDesktopHome">
            <source
              srcset="@/assets/portfolio/fhg-news/sc_desktop_home.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/fhg-news/sc_desktop_home.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/fhg-news/sc_desktop_home.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scDesktopArticle">
            <source
              srcset="@/assets/portfolio/fhg-news/sc_desktop_article.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/fhg-news/sc_desktop_article.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/fhg-news/sc_desktop_article.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scDesktopContribution">
            <source
              srcset="@/assets/portfolio/fhg-news/sc_desktop_contribution.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/fhg-news/sc_desktop_contribution.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/fhg-news/sc_desktop_contribution.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scDesktopContact">
            <source
              srcset="@/assets/portfolio/fhg-news/sc_desktop_contact.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/fhg-news/sc_desktop_contact.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/fhg-news/sc_desktop_contact.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scMobileAbout">
            <source
              srcset="@/assets/portfolio/fhg-news/sc_mobile_about.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/fhg-news/sc_mobile_about.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/fhg-news/sc_mobile_about.jpg"
              alt="Screenshot"
            />
          </picture>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioIntro from './layout/PortfolioIntro';
import PortfolioMarker from './layout/PortfolioMarker';
import PortfolioColorSwatch from './layout/PortfolioColorSwatch';
import PortfolioFont from './layout/PortfolioFont';

import Rellax from 'rellax';

export default {
  name: 'FhgNews',
  components: {
    PortfolioIntro,
    PortfolioMarker,
    PortfolioColorSwatch,
    PortfolioFont,
  },
  metaInfo() {
    return {
      title: 'FHG News ◃ Portfolio ◂ Dennis Adamczyk',
      meta: [
        {
          name: 'description',
          content:
            'FHG News – Portfolio – Dennis Adamczyk • Web Developer & UI/UX Designer – School newspaper of Franz-Haniel-Gymnasium',
        },
        {
          name: 'keywords',
          content:
            'dennis adamczyk, fhg news, fhgnews, schülerzeitung, blog, portfolio, projekt',
        },
      ],
    };
  },
  mounted() {
    new Rellax('.rellax', {
      breakpoints: [600, 768, 1201],
    });
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    languageSuffix() {
      return this.$i18n.locale !== this.$i18n.fallbackLocale
        ? `-${this.$i18n.locale}`
        : '';
    },
  },
  methods: {
    handleScroll() {
      let visitSiteBtn = document.querySelector('#result .visitSite');
      let resultSection = document.getElementById('result');
      let footer = document.querySelector('#app > footer');

      if (
        window.scrollY >
        document.documentElement.scrollHeight - resultSection.offsetHeight
      ) {
        if (!visitSiteBtn.classList.contains('show'))
          visitSiteBtn.classList.add('show');

        let updateBottom = () => {
          if (
            window.scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footer.offsetHeight
          ) {
            let newBottom =
              window.scrollY +
              window.innerHeight -
              (document.documentElement.scrollHeight - footer.offsetHeight);
            visitSiteBtn.style.setProperty(
              'transition',
              'background 120ms ease, bottom 0ms ease, opacity 120ms ease'
            );
            visitSiteBtn.style.setProperty(
              'bottom',
              (newBottom || 0) + 24 + 'px'
            );
          } else {
            visitSiteBtn.removeAttribute('style');
          }
        };
        updateBottom();
        setTimeout(updateBottom, 20);
      } else {
        if (visitSiteBtn.classList.contains('show'))
          visitSiteBtn.classList.remove('show');
      }
    },
  },
};
</script>

<style lang="scss" src="./layout/projects.scss" scoped></style>
<style lang="scss" scoped>
#intro {
  position: relative;
  z-index: 1;
}

#idea {
  background-color: $background-light;
  overflow: hidden;

  @media all and ($mobile) {
    background-color: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -128px;
  }

  .outer {
    background-color: $background-light;

    @media all and ($mobile) {
      border-top: 128px solid $background;
      padding-bottom: 96px;
    }

    .content {
      @media all and ($mobile) {
        margin-top: -128px;
      }

      .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media all and ($mobile) {
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: stretch;
        }

        .mockup {
          margin-left: 1%;
          width: 80%;
          min-width: 80%;
          position: relative;
          z-index: 1;

          @media all and ($mobile) {
            margin-left: -24px;
            margin-right: 0;
            margin-bottom: 0;
            width: calc(100% + 2 * 24px);
            max-width: none;
            display: flex;
            justify-content: center;
            align-items: center;
            position: initial;
            z-index: 0;
          }

          img {
            width: 100%;
            height: auto;

            @media all and ($mobile) {
              width: 150%;
            }
          }
        }

        .text {
          width: 50%;
          min-width: 50%;
          position: relative;

          @media all and ($mobile) {
            margin-top: 256px;
            width: 100%;
            margin-top: auto;
            margin-left: 0;
          }

          article {
            z-index: 1;

            &:last-of-type {
              margin-bottom: 0;

              p:last-child {
                margin-bottom: 0;
              }
            }
          }

          .background-text {
            top: -144px;
            left: 100%;

            @media all and ($mobile) {
              left: -38px;
              top: -144px;
            }
          }
        }
      }
    }
  }
}

#concept {
  .content {
    .editors {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

#target {
  background-color: $background-light;

  .content {
    .background-text {
      left: 7%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .groups {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media all and ($mobile) {
        display: block;
      }

      .group {
        width: calc(50% - 24px);

        &:last-child {
          margin-bottom: 0;
        }

        @media all and ($desktop) {
          margin-bottom: 0;
        }

        @media all and ($mobile) {
          width: 100%;
        }

        .description {
          & ::v-deep span {
            color: $primary;
          }
        }
      }
    }
  }
}

#design {
  .content {
    .background-text {
      left: 25%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .ui {
      .material,
      .responsive {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
        }

        .text {
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media all and ($mobile) {
            width: 100%;
            margin-bottom: 48px;
          }

          .icon {
            display: block;
            font-size: 96px;
            width: 96px;
            height: 96px;
            color: $foreground;
            text-align: center;
            margin: 0 auto;
          }

          .heading {
            margin: 0 auto;
            margin-top: 12px;
            width: 144px;
            font-size: 1.125rem;
            font-weight: 700;
            color: $foreground;
            text-align: center;
          }

          .pros {
            margin-top: 48px;
            list-style: none;

            li {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: $foreground;
              margin-bottom: 12px;

              &::before {
                content: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cg%20transform%3D%22translate(-153%20-5385)%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20transform%3D%22translate(153%205385)%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M19%2C13H13v6H11V13H5V11h6V5h2v6h6Z%22%20transform%3D%22translate(153%205385)%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
                display: inline-block;
                margin-right: 8px;
              }
            }
          }
        }

        .components {
          padding: 0 5%;
          max-width: 40%;

          @media all and ($mobile) {
            max-width: 65%;
            width: 65%;
          }

          .inner {
            position: relative;

            picture {
              display: flex;
              justify-content: stretch;
              align-items: center;
              border-radius: 16px;
              box-shadow: map-get($elevation, 1);
              overflow: hidden;

              img {
                width: 100%;
                height: auto;
              }
            }

            & > *:not(picture) {
              position: absolute;

              &.navigationDrawer {
                top: 3%;
                right: 95.5%;
                width: calc(24px + 4.5%);

                @media all and ($mobile) {
                  width: calc(16px + 4.5%);
                }
              }

              &.actionButton {
                top: 64.9%;
                right: 95.5%;
                width: calc(24px + 4.5%);

                @media all and ($mobile) {
                  width: calc(16px + 4.5%);
                }
              }

              &.appBar {
                top: 1%;
                left: 100%;
                width: calc(24px);

                @media all and ($mobile) {
                  width: calc(16px);
                }
              }

              &.iconButton {
                top: 6.5%;
                left: 80%;
                width: calc(24px + 20%);

                @media all and ($mobile) {
                  width: calc(16px + 20%);
                }
              }

              &.card {
                top: 25%;
                left: 95%;
                width: calc(24px + 5%);

                @media all and ($mobile) {
                  width: calc(16px + 5%);
                }
              }

              &.fixedFooter {
                top: 92.5%;
                left: 97%;
                width: calc(24px + 3%);

                @media all and ($mobile) {
                  width: calc(16px + 3%);
                }
              }
            }
          }
        }
      }
    }

    .colors {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media all and ($mobile) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }

      .text {
        margin-right: 24px;

        @media all and ($mobile) {
          width: 100%;
          min-width: 100%;
          margin-right: 0;
        }
      }

      .swatches {
        display: grid;
        grid-template-columns: 2fr repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          'first second third'
          'first fourth fifth';
        gap: 24px;
        width: 65%;
        min-width: 65%;

        @media all and ($mobile) {
          grid-template-columns: 1.5fr repeat(2, 1fr);
          width: 100%;
          min-width: 100%;
          gap: 16px;
        }

        &::before {
          content: '';
          width: 0;
          padding-bottom: calc(50% - 12px);
          grid-row: 1 / 1;
          grid-column: 1 / 1;

          @media all and ($mobile) {
            padding-bottom: calc(50% - 8px);
          }
        }

        & > * {
          &:nth-child(1) {
            grid-area: first;
          }
          &:nth-child(2) {
            grid-area: second;
          }
          &:nth-child(3) {
            grid-area: third;
          }
          &:nth-child(4) {
            grid-area: fourth;
          }
          &:nth-child(5) {
            grid-area: fifth;
          }
        }
      }
    }

    .typography {
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        .description {
          padding-top: 16px;
          min-width: 50%;
          max-width: 50%;
          white-space: pre-line;

          @media all and ($mobile) {
            padding-top: 0;
            padding-bottom: 24px;
            min-width: 100%;
            max-width: 100%;
          }
        }

        .fonts {
          margin-top: -32px;
          margin-left: 80px;

          @media all and ($mobile) {
            margin-top: 0;
            margin-left: 0;
          }

          & > * {
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

#result {
  background-color: $background-light;

  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background-color: $background;
    top: 32%;
    height: 68%;
    z-index: 0;

    @media all and ($mobile) {
      top: 60%;
      height: 40%;
    }
  }

  .content {
    z-index: 1;

    .background-text {
      left: 25%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .gallery {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: auto;
      grid-template-areas:
        'scMobileHome scDesktopHome scDesktopHome'
        'scMobileHome scDesktopArticle scDesktopArticle'
        'scMobileHome . scMobileAbout'
        'scDesktopContribution scDesktopContribution scMobileAbout'
        'scDesktopContact scDesktopContact scMobileAbout';
      gap: 32px;
      align-items: start;
      width: 100%;
      max-width: 100%;
      margin-top: 32px;

      @media all and ($mobile) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          'scMobileHome scMobileAbout'
          'scDesktopHome scDesktopHome'
          'scDesktopArticle scDesktopArticle'
          'scDesktopContribution scDesktopContribution'
          'scDesktopContact scDesktopContact';
        gap: 16px;
      }

      picture {
        display: flex;
        justify-content: stretch;
        align-items: center;
        width: 100%;
        border-radius: 20px;
        box-shadow: map-get($elevation, 3);
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .scMobileHome {
        grid-area: scMobileHome;
        border-radius: 12px;
      }
      .scDesktopHome {
        grid-area: scDesktopHome;
      }
      .scDesktopArticle {
        grid-area: scDesktopArticle;
      }
      .scMobileAbout {
        grid-area: scMobileAbout;
        border-radius: 12px;

        @media all and ($mobile) {
          align-self: center;
        }
      }
      .scDesktopContribution {
        grid-area: scDesktopContribution;
      }
      .scDesktopContact {
        grid-area: scDesktopContact;
      }
    }
  }
}
</style>
